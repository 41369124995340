<template>
  <b-container>
    <h1>Email</h1>
    <b-form v-on:submit.prevent="sendEmail">
      <b-row>
        <b-col md="6">
          <b-form-group id="template" label="Template:" label-for="template">
            <b-form-select
              id="template"
              v-model="template"
              :options="options"
              v-bind:disabled="busy"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group id="title" label="Title:" label-for="title">
            <b-form-input
              id="title"
              v-model="emailTitle"
              v-bind:disabled="busy"
              v-on:keydown.enter.prevent
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            id="destination"
            label="To:"
            label-for="destination"
            description="Enter only one email address"
          >
            <b-form-input
              required
              id="destination"
              v-model="destination"
              v-bind:disabled="busy"
              v-on:keydown.enter.prevent
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            required
            id="source"
            label="From:"
            label-for="source"
            description="Enter only one email address. Must be a reducer.co.uk domain address."
          >
            <b-form-input
              required
              id="source"
              v-model="source"
              v-bind:disabled="busy"
              v-on:keydown.enter.prevent
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group id="subject" label="Subject:" label-for="subject">
        <b-form-input
          required
          id="subject"
          v-model="subject"
          v-bind:disabled="busy"
          v-on:keydown.enter.prevent
        ></b-form-input>
      </b-form-group>
      <b-form-group id="preheader" label="Preheader:" label-for="preheader">
        <b-form-input
          id="preheader"
          v-model="preheader"
          v-bind:disabled="busy"
          v-on:keydown.enter.prevent
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="email1"
        label
        label-for="email1"
        description="Valid html is accepted. Line breaks will automatically be converted into html breaks."
      >
        <b-form-textarea
          id="email1"
          v-model="email1"
          placeholder="Hi..."
          rows="6"
          max-rows="20"
          v-bind:disabled="busy"
        ></b-form-textarea>
      </b-form-group>
      <b-row v-if="template == 'BasicWithReportLink'">
        <b-col md="6">
          <b-form-group id="redirect" label="MagicLink redirect:" label-for="redirect">
            <b-form-input
              id="redirect"
              placeholder="savings/12345..."
              v-model="redirect"
              v-bind:disabled="busy"
              v-on:keydown.enter.prevent
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group id="buttonLabel" label="Text to go on button:" label-for="buttonLabel">
            <b-form-input
              id="buttonLabel"
              v-model="buttonLabel"
              v-bind:disabled="busy"
              v-on:keydown.enter.prevent
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group
        v-if="template == 'BasicWithReportLink'"
        id="email2"
        label
        label-for="email2"
        description="Valid html is accepted. Line breaks will automatically be converted into html breaks."
      >
        <b-form-textarea
          id="email2"
          v-model="email2"
          placeholder="Thanks..."
          rows="6"
          max-rows="20"
          v-bind:disabled="busy"
        ></b-form-textarea>
      </b-form-group>
      <b-row class="d-flex flex-row-reverse">
        <b-button class="mr-3" v-bind:disabled="busy" type="submit" variant="primary"
          >Send</b-button
        >
      </b-row>
    </b-form>
  </b-container>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";

export default {
  name: "email",
  data() {
    return {
      title: "Reducer - Email",
      template: "Basic",
      emailTitle: "Reducer",
      options: [
        { value: "Basic", text: "Basic" },
        { value: "BasicWithReportLink", text: "With MagicLink" },
        { value: "Testing", text: "Testing Only" }
      ],
      email1: "",
      email2: "",
      redirect: "",
      buttonLabel: "View Report",
      destination: "",
      source: "support@reducer.co.uk",
      subject: "",
      preheader: "",
      busy: false
    };
  },
  methods: {
    async sendEmail() {
      Console.log(this.source);
      this.busy = true;
      try {
        let client = await ApiHelper.http();
        await client
          .post(`${ApiHelper.endPoint()}email/sendEmail`, {
            destination: this.destination,
            paragraphOne: this.email1,
            paragraphTwo: this.email2,
            source: this.source,
            subject: this.subject,
            templateName: this.template,
            magicLinkRedirect: this.redirect,
            buttonText: this.buttonLabel,
            title: this.emailTitle,
            preheader: this.preheader
          })
          .then((response) => {
            Console.log(response);
            this.showSuccess("Email sent");
            this.resetForm();
          })
          .catch((error) => {
            Console.log(error);
            this.showWarning("Sending email failed: " + error);
          });
      } catch {
        this.showWarning("Sending email failed");
      } finally {
        this.busy = false;
      }
    },
    resetForm() {
      this.email1 = "";
      this.email2 = "";
      this.redirect = "";
      this.buttonLabel = "View Report";
      this.destination = "";
      this.source = "support@reducer.co.uk";
      this.subject = "";
      this.emailTitle = "Reducer";
      this.preheader = "";
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    },
    showSuccess(message) {
      EventBus.$emit("show-toast", { message: message, variant: "success" });
    }
  }
};
</script>